type VideoPlayerProps = {
  videoSource: string;
};

const VideoPlayer = ({ videoSource }: VideoPlayerProps) => {
  return (
    <video muted autoPlay>
      <source src={videoSource} type='video/mp4' />
    </video>
  );
};

export default VideoPlayer;
