/* eslint-disable prefer-const */
import {
  ICategoryField,
  ICategoryGroup,
  IHTTPInterface,
  IInvoiceConfiguration,
  ITokenizerConfig,
} from '../../../core/interfaces/index';
import {
  useState,
  useEffect,
  useContext,
  // createRef
} from 'react';
import axios, { AxiosResponse } from 'axios';
import { invoiceEndpoints } from '../../../core/constants/endpoints';
import { useParams } from 'react-router';
import { stringFormat } from '../../Global/Tokenizer/tokenizerHelpers';
import CustomerPayablesPayment from './CustomerPayablesPayment';
import '../../../assets/sass/pages/customerPayables.scss';
import ErrorIcon from '@mui/icons-material/Error';
import AlertModal from '../../Global/Tokenizer/AlertModal';
import TotalSelectedToPay from './TotalSelectedToPay';
import {
  Table,
  TableBody,
  TableHead,
  Grid,
  Backdrop,
  CircularProgress,
  TablePagination,
  TableContainer,
  // useTheme,
} from '@mui/material';
import { formatDateTime, formatTransactionCurrency } from '../../../core/helpers/stringHelpers';
import InvoiceStatusLegend from './InvoiceStatusLegend';
import InvoiceListTotalsRow from './InvoiceListTotalsRow';
import InvoiceRow from './InvoiceRow';
import InvoiceHeaderRow from './InvoiceHeaderRow';
import { useNavigate } from 'react-router-dom';
import InvoicesSubmitModal from './InvoicesSubmitModal';
import { formatNumber } from '../../../core/helpers/stringHelpers';
import { IInvoiceResponse } from '../../../core/interfaces/index';
import PaymentError from '../Payment/PaymentError';
import { CustomerPayablesContext } from '../../../contexts/CustomerPayablesContext';
import { IGetPayableResponse, IResponseSummary } from '../../../core/interfaces/customerPayables.interface';
// import ReCAPTCHA from 'react-google-recaptcha';
import { ILayout, LayoutContext } from '../../../contexts/LayoutContext';
import ColorContrastChecker from 'color-contrast-checker';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
export type ICalculateFeesProps = {
  invoiceToken: string;
  creditCardBin: string;
  state: string | undefined;
  baseAmount: number | string | null;
  addTaxToTotal: boolean;
  taxAmount: number;
  processorId: string;
};

export type CustomerPayableInterface = {
  id: number;
  invoiceId: string[] | undefined;
  invoiceToken: string;
  invoiceDate?: string;
  invoiceNumber?: string;
  dueDate?: string;
  originalBalance?: number;
  remainingBalance?: number;
  invoicePaymentMethod: { [tabString: string]: boolean };
  surcharge?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  amountPay?: any;
  total?: number;
};

export interface IBranding {
  logo: string;
  footer: string;
  primary: string;
  secondary: string;
}

const CustomerPayables = () => {
  const { payableToken } = useParams();
  const navigate = useNavigate();
  // const recaptcha = createRef<ReCAPTCHA>();
  // const theme = useTheme();
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
  // const [recaptchaChecked, setRecaptchaChecked] = useState(false);
  // const [errorBanner, setErrorBanner] = useState<string>('');
  const [displayedRows, setDisplayedRows] = useState<CustomerPayableInterface[]>([]);
  const [summary, setSummary] = useState<IResponseSummary>({});
  const [originalResponse, setOriginalResponse] = useState<IGetPayableResponse[]>([]);
  const [errorToken, setErrorToken] = useState<number | null>(null);
  const [selectedRows, setSelectedRows] = useState<CustomerPayableInterface[]>([]);
  const [allChecked, setAllChecked] = useState(false);
  const [tokenConfiguration, setTokenConfiguration] = useState<ITokenizerConfig | null>(null);
  const [amountToPay, setAmountsToPay] = useState<{ [key: string]: string }>({});
  const [savedSurcharges, setSavedSurcharges] = useState<{ [key: string]: number }>({});
  const [invoice, setInvoice] = useState<IInvoiceResponse | null>(null);
  const [surcharge, setSurcharge] = useState(false);
  const [binFilled, setBinFilled] = useState<boolean | string>(false);
  const [paymentTab, setPaymentTab] = useState('card');
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [totalCount, setTotalCount] = useState(0);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [loadingSurcharge, setLoadingSurcharge] = useState(false);
  const [loadingTenSurcharges, setLoadingTenSurcharges] = useState(false);
  const [surchargeError, setSurchargeError] = useState(false);
  const [showSurchargeModal, setShowSurchargeModal] = useState(false);
  const [showMaxModal, setShowMaxModal] = useState(false);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [selectedPagesCounter, setSelectedPagesCounter] = useState<{ [page: number]: number; total: number }>({
    0: 0,
    total: 0,
  });
  const [reachedMax, setReachedMax] = useState(false);
  const [invoiceSubmit, setInvoiceSubmit] = useState(false);
  const [modalAmounts, setModalAmounts] = useState(amountToPay);
  const [modalRows, setModalRows] = useState(selectedRows);
  const [modalSurcharges, setModalSurcharges] = useState(savedSurcharges);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [surchargeState, setSurchargeState] = useState<string>('');
  const [errorList, setErrorList] = useState<string[]>([]);

  const totalAmountToPay = Object.keys(amountToPay).reduce((sum, key) => {
    return sum + parseFloat(amountToPay[key].replace(/,/g, ''));
  }, 0);
  const totalSurcharge = Object.keys(savedSurcharges).reduce((a, r) => {
    return a + savedSurcharges[r];
  }, 0);

  const layoutContext = useContext(LayoutContext);

  // const recaptchaCallback = () => {
  //   setRecaptchaChecked(true);
  // };

  useEffect(() => {
    retrieveCustomerPayableInvoices(payableToken!);
  }, [page]);

  useEffect(() => {
    if (invoice) {
      retrieveTokenizerConfiguration(invoice.invoiceToken!);
    }
  }, [invoice]);

  useEffect(() => {
    if (selectedRows.length > 9) {
      setShowMaxModal(true);
      setReachedMax(true);
    } else {
      if (reachedMax) {
        setReachedMax(false);
      }
    }
  }, [selectedRows]);

  useEffect(() => {
    if (surcharge && paymentTab === 'card') {
      if (binFilled && surchargeState !== '' && selectedRows.length > 0) {
        setLoadingSurcharge(true);
        getSurchargeAmount(
          selectedRows.map((row) => row.invoiceId![1]),
          null,
          true
        );
      }
    }
  }, [binFilled, surchargeState]);

  //deselect rows if the newly selected payment tab is not a supported payment method or surcharge but bin not filled(?)
  //if surcharge is enabled and bin is filled and we switch from ach to card, recalculate surcharges
  //delete/add surcharge and total columns depending on payment tab selected
  const handleSwitchPayment = (tab: string) => {
    let deselectedRows: string[] = [];
    selectedRows.forEach((row) => {
      const id = row.invoiceId![1];
      if (
        ((tab === 'card' || tab === 'ach') &&
          originalResponse.find((row) => row.invoiceId === id)?.invoicePaymentMethod[tab] === false) ||
        (surcharge && !binFilled && tab === 'card')
      ) {
        deselectedRows.push(id);
      }
    });
    if (deselectedRows.length > 0) {
      let newAmounts = { ...amountToPay };
      let newSurcharge = { ...savedSurcharges };
      deselectedRows.forEach((id) => {
        newAmounts[id] = '0.00';
        newSurcharge[id] = 0;
      });
      setAmountsToPay(newAmounts);
      setSavedSurcharges(newSurcharge);
      let newSelectedRows = selectedRows.filter((row) => !deselectedRows.includes(row.invoiceId![1]));
      if (surcharge && tab === 'card') {
        if (binFilled) {
          setLoadingSurcharge(true);
          getSurchargeAmount(
            newSelectedRows.map((row) => row.invoiceId![1]),
            null,
            true
          );
        } else {
          let newRows = displayedRows.map((row) => {
            return { ...row, surcharge: 0, total: 0 };
          });
          setDisplayedRows(newRows);
        }
        //add surcharge and total columns in invoiceSubmissionModal
        setSelectedRows(
          newSelectedRows.map((row) => {
            return { ...row, surcharge: 0, total: 0 };
          })
        );
      } else {
        setSelectedRows(newSelectedRows);
      }
      if (allChecked) {
        setAllChecked(false);
      }
    } else {
      if (surcharge && tab === 'card') {
        if (binFilled) {
          setLoadingSurcharge(true);
          getSurchargeAmount(
            selectedRows.map((row) => row.invoiceId![1]),
            null,
            true
          );
        } else {
          let newRows = displayedRows.map((row) => {
            return { ...row, surcharge: 0, total: 0 };
          });
          setDisplayedRows(newRows);
        }
        //add surcharge and total columns in invoiceSubmissionModal
        setSelectedRows(
          selectedRows.map((row) => {
            return { ...row, surcharge: 0, total: 0 };
          })
        );
      }
    }
    if (tab === 'ach') {
      displayedRows.forEach((row) => {
        delete row.surcharge;
        delete row.total;
      });
      setDisplayedRows(displayedRows);
    }
  };

  const retrieveTokenizerConfiguration = (token: string) => {
    setOpenBackdrop(true);
    const tokenUrl: Promise<AxiosResponse<IHTTPInterface>> = axios.get(
      stringFormat(invoiceEndpoints.tokenizationConfiguration, [token]),
      {
        headers: {
          Authorization: token,
        },
      }
    );
    const templateUrl: Promise<AxiosResponse<IHTTPInterface>> = axios.get(
      stringFormat(invoiceEndpoints.invoiceConfiguration, [token]),
      {
        headers: {
          Authorization: token,
        },
      }
    );
    axios
      .all([tokenUrl, templateUrl])
      .then(
        axios.spread((...res) => {
          setTemplateFields(res[1]?.data.data);
          setTokenConfiguration(res[0]?.data?.data);
        })
      )
      .catch(() => {
        setErrorToken(405);
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const setTemplateFields = (configResponse: IInvoiceConfiguration | undefined) => {
    const template: ICategoryGroup | undefined = configResponse?.configuration?.categories
      .find((c) => {
        return c.code === 'templates';
      })
      ?.groups.find((g) => {
        return g.name === 'Customer Payables';
      });
    const templateFields: ICategoryField[] = template?.fields || [];
    const root = document.documentElement;
    const ccc = new ColorContrastChecker();
    const primaryColor: string =
      templateFields.find((t) => {
        return t.code === 'add_customer_colors_main_color';
      })?.value?.[0] || '#189ad0';
    const secondaryColor: string =
      templateFields.find((t) => {
        return t.code === 'add_customer_colors_secondary_color';
      })?.value?.[0] || '#0a1a72';
    root?.style.setProperty('--branding-primary', primaryColor);
    root?.style.setProperty('--branding-secondary', secondaryColor);
    root?.style.setProperty('--branding-typography-primary', ccc.isLevelAA(primaryColor, '#FFF') ? '#FFF' : '#000');
    root?.style.setProperty('--branding-typography-secondary', ccc.isLevelAA(secondaryColor, '#FFF') ? '#FFF' : '#000');

    const images: ILayout = {
      header:
        templateFields.find((t) => {
          return t.code === 'add_logo_url';
        })?.value || '',
      footer:
        templateFields.find((t) => {
          return t.code === 'add_footer_image_url';
        })?.value || '',
    };
    layoutContext?.setLayout(images);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if (!Object.keys(selectedPagesCounter).includes(newPage.toString())) {
      setSelectedPagesCounter({ ...selectedPagesCounter, [newPage]: 0 });
    }
    setAllChecked(false);
  };

  const retrieveCustomerPayableInvoices = (token: string) => {
    setOpenBackdrop(true);
    const url: string = stringFormat(invoiceEndpoints.customer.customerPayable, [
      token,
      rowsPerPage.toString(),
      (page * rowsPerPage).toString(),
    ]);
    axios
      .get(url, {
        headers: {
          Authorization: token,
        },
      })
      .then((response: AxiosResponse<IHTTPInterface>) => {
        if (response.status === 204) {
          setErrorToken(204);
        } else {
          setErrorToken(null);
          setSummary(response?.data?.data?.summary);
          let responseInvoices: IGetPayableResponse[] = response?.data?.data?.results;
          const originalResponseIds = originalResponse.map((invoice) => invoice.invoiceId);
          const newInvoices: IGetPayableResponse[] = responseInvoices.filter(
            (row) => !originalResponseIds.includes(row.invoiceId)
          );
          if (newInvoices.length > 0) {
            setOriginalResponse(originalResponse.concat(newInvoices));
          } else if (originalResponse.length === 0) {
            setOriginalResponse(responseInvoices);
          }
          setInvoice(response?.data?.data?.results[0]);
          setSurcharge(responseInvoices[0].surcharge);
          setTotalCount(response?.data?.data?.rowCount);
          const newRows = responseInvoices.map((row, i) => {
            return {
              id: i,
              invoiceToken: row.invoiceToken,
              invoiceId: [
                row.invoiceStatus.name === 'Sent'
                  ? 'sent'
                  : row.invoiceStatus.name === 'Partially Paid'
                  ? 'partiallyPaid'
                  : 'overdue',
                row.invoiceId,
              ],
              invoiceNumber: row.number,
              invoicePaymentMethod: { card: row.invoicePaymentMethod.card, ach: row.invoicePaymentMethod.ach },
              invoiceDate: formatDateTime(row.invoiceDate).split(' ')[0],
              dueDate: formatDateTime(row.dueDate).split(' ')[0],
              originalBalance: row.total,
              remainingBalance: row.remainingBalance,
              amountPay: 0,
              ...(responseInvoices[0].surcharge && paymentTab === 'card' && { surcharge: 0 }),
              ...(responseInvoices[0].surcharge && paymentTab === 'card' && { total: 0 }),
            };
          });
          setDisplayedRows(newRows);

          let newAmounts = { ...amountToPay };
          let newSurcharges = { ...savedSurcharges };
          newRows.forEach((row) => {
            if (!amountToPay[row.invoiceId![1]]) {
              newAmounts[row.invoiceId![1]] = '0.00';
            }
            if (!savedSurcharges[row.invoiceId![1]]) {
              newSurcharges[row.invoiceId![1]] = 0;
            }
          });
          setAmountsToPay(newAmounts);
          setSavedSurcharges(newSurcharges);
        }
      })
      .catch(() => {
        setErrorToken(401);
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const handleSelectInvoice = (
    // event: React.MouseEvent<unknown>,
    id: string
  ) => {
    let foundInvoiceRow = displayedRows.find((row) => row.invoiceId![1] === id);
    let newCounter = { ...selectedPagesCounter };
    let foundSelectedRowIndex = 0;

    if (
      selectedRows.find((row, i) => {
        if (row.invoiceId![1] === id) {
          foundSelectedRowIndex = i;
        }
        return row.invoiceId![1] === id;
      })
    ) {
      const newSelectedRows = selectedRows
        .slice(0, foundSelectedRowIndex)
        .concat(selectedRows.slice(foundSelectedRowIndex + 1, selectedRows.length));
      setSelectedRows(newSelectedRows);
      setAmountsToPay({ ...amountToPay, [id]: '0.00' });
      setSavedSurcharges({ ...savedSurcharges, [id]: 0 });
      newCounter[page] -= 1;
      newCounter.total -= 1;
      setSelectedPagesCounter(newCounter);
    } else if (foundInvoiceRow) {
      setSelectedRows([...selectedRows, foundInvoiceRow]);
      setAmountsToPay({
        ...amountToPay,
        [id]: formatNumber(parseInt((foundInvoiceRow.remainingBalance! * 100).toFixed(2).toString()), 2, '0,0.00'),
      });
      if (surcharge && paymentTab === 'card' && foundInvoiceRow.surcharge === 0) {
        setLoadingSurcharge(true);
        getSurchargeAmount([id], foundInvoiceRow.remainingBalance!);
      }
      newCounter[page] += 1;
      newCounter.total += 1;
      setSelectedPagesCounter(newCounter);
    }
  };

  //call when amount to pay changes in invoice list/ payment method switched
  const getSurchargeAmount = async (idArray: string[], inputValue?: number | null, recalculating?: boolean) => {
    //disable text field when a batch of invoices are selected
    if (!inputValue) {
      setLoadingTenSurcharges(true);
    }
    let invoicesFound = originalResponse.filter((row) => idArray.includes(row.invoiceId));
    if (invoicesFound && typeof binFilled === 'string') {
      let payloads = invoicesFound.map((invoice) => {
        return {
          invoiceToken: invoice.invoiceToken,
          creditCardBin: binFilled,
          state: surchargeState,
          baseAmount: recalculating
            ? Number(amountToPay[invoice.invoiceId])
            : inputValue !== undefined
            ? inputValue
            : invoice.remainingBalance,
          addTaxToTotal: false,
          taxAmount: invoice.tax,
          processorId: invoice.invoicePaymentMethod.cardProcessorId,
        };
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const surchargeResponse: any = await calculateFees(payloads);

      let updatedRows: CustomerPayableInterface[] = [];
      let updatedSurcharge = { ...savedSurcharges };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      surchargeResponse.forEach((obj: any) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let foundInvoiceId = originalResponse.find((r: any) => r.invoiceToken === obj.invoiceToken)?.invoiceId;
        if (foundInvoiceId) {
          if (obj.surchargeAmount === null) {
            setSelectedRows(selectedRows.filter((r) => r.invoiceId![1] !== foundInvoiceId));
            setAmountsToPay({ ...amountToPay, [foundInvoiceId]: '0.00' });
          }
          updatedSurcharge[foundInvoiceId] = obj.surchargeAmount;
        }
      });
      displayedRows.forEach((row) => {
        if (recalculating) {
          updatedRows.push({ ...row, surcharge: 0, total: 0 });
        } else {
          updatedRows.push(row);
        }
      });
      setSavedSurcharges(updatedSurcharge);
      setDisplayedRows(updatedRows);
      if (!inputValue) {
        setLoadingTenSurcharges(false);
      }
      setLoadingSurcharge(false);
    }
  };

  const calculateFees = async (payloadArray: ICalculateFeesProps[]) => {
    let axiosCalls = payloadArray.map((obj, i) =>
      axios
        .post(stringFormat(invoiceEndpoints.calculateFees, [obj.invoiceToken]), payloadArray[i], {
          headers: {
            Authorization: obj.invoiceToken,
          },
        })
        .catch(() => {
          return null;
        })
    );
    return axios
      .all(axiosCalls)
      .then(
        axios.spread((...res) => {
          let returnedSurcharges = res.map((r, i) => {
            if (r === null) {
              return {
                invoiceToken: payloadArray[i].invoiceToken,
                surchargeAmount: null,
              };
            }
            r.data.data.invoiceToken = payloadArray[i].invoiceToken;
            if (r.data.data.isSurchargeable) {
              return r.data.data;
            } else {
              r.data.data.surchargeAmount = 0;
              return r.data.data;
            }
          });

          if (
            returnedSurcharges.some((r) => {
              return r.surchargeAmount === null;
            })
          ) {
            setSurchargeError(true);
            setShowSurchargeModal(true);
            setAllChecked(false);
          } else {
            setSurchargeError(false);
          }
          return returnedSurcharges;
        })
      )
      .catch(() => {
        setSurchargeError(true);
        setShowSurchargeModal(true);
      });
  };

  //checks all selectable invoices on that page up to 10
  //only calculates newly selected invoices' surcharges
  const selectAllInvoices = () => {
    let newAmounts = { ...amountToPay };
    let newSurcharge = { ...savedSurcharges };

    if (!allChecked) {
      if (selectedRows.length > 9) {
        setShowMaxModal(true);
      } else {
        let selectedRowIds = selectedRows.map((r) => r.invoiceId![1]);
        const newSelectedInvoices = displayedRows
          .filter((row) => {
            return (
              row.invoicePaymentMethod[paymentTab] === true &&
              savedSurcharges[row.invoiceId![1]] !== null &&
              !selectedRowIds.includes(row.invoiceId![1])
            );
          })
          .slice(0, 10 - selectedRows.length);

        newSelectedInvoices.forEach((row) => {
          const id = row.invoiceId![1];
          let foundInvoice = displayedRows.find((row) => row.invoiceId![1] === id);
          if (foundInvoice) {
            newAmounts[id] = formatNumber(
              parseInt((foundInvoice.remainingBalance! * 100).toFixed(2).toString()),
              2,
              '0,0.00'
            );
          } else {
            newAmounts[id] = '0.00';
          }
        });
        setAmountsToPay(newAmounts);
        let newCounter = { ...selectedPagesCounter };
        newCounter[page] = newSelectedInvoices.length + selectedPagesCounter[page];
        newCounter.total = selectedPagesCounter.total + newSelectedInvoices.length;
        setSelectedPagesCounter(newCounter);
        setSelectedRows(newSelectedInvoices.concat(selectedRows));

        //only calculates newly selected invoices' surcharges
        if (surcharge && paymentTab === 'card') {
          setLoadingSurcharge(true);
          getSurchargeAmount(newSelectedInvoices.map((row) => row.invoiceId![1]));
        }
        setAllChecked(true);
      }
    } else {
      let newSelectedRows = selectedRows.filter(
        (r) => !displayedRows.map((row) => row.invoiceId![1]).includes(r.invoiceId![1])
      );
      displayedRows.forEach((row) => {
        newAmounts[row.invoiceId![1]] = '0.00';
        newSurcharge[row.invoiceId![1]] = 0;
      });
      setAmountsToPay(newAmounts);
      setSelectedRows(newSelectedRows);
      setSavedSurcharges(newSurcharge);
      setAllChecked(false);
      let newCounter = { ...selectedPagesCounter };
      newCounter[page] = 0;
      newCounter.total = selectedPagesCounter.total - selectedPagesCounter[page];
      setSelectedPagesCounter(newCounter);
    }
  };

  const disabledTooltip = () => {
    return (
      <Tooltip title={page > 8 ? 'Maximum invoice limit reached.' : 'Calculating surcharge'}>
        <span>
          <IconButton disabled={true}>
            <ChevronRightIcon></ChevronRightIcon>
          </IconButton>
        </span>
      </Tooltip>
    );
  };

  return (
    <>
      <CustomerPayablesContext.Provider
        value={{
          displayedRows,
          selectedRows,
          surcharge,
          paymentTab,
          savedSurcharges,
          setSavedSurcharges,
          amountToPay,
          binFilled,
          modalAmounts,
          modalRows,
          modalSurcharges,
          disableSubmit,
          setDisableSubmit,
          originalResponse,
          tokenConfiguration,
          setDisplayedRows,
          setBinFilled,
          setPaymentTab,
          handleSwitchPayment,
          loadingSurcharge,
          setOpenSubmitModal,
          selectedPagesCounter,
          page,
          invoiceSubmit,
          setLoadingSurcharge,
          summary,
          setInvoiceSubmit,
          submitClicked,
          setSubmitClicked,
          surchargeState,
          setSurchargeState,
          errorList,
          setErrorList,
        }}
      >
        <Backdrop sx={{ color: '#fff', zIndex: 9000, opacity: 0.5 }} open={openBackdrop}>
          <CircularProgress color='inherit' />
        </Backdrop>
        <AlertModal
          tabOpen={showSurchargeModal || showMaxModal}
          setTabOpen={showSurchargeModal ? setShowSurchargeModal : setShowMaxModal}
          alertTitle=''
          alertText={
            showSurchargeModal
              ? 'One or more invoices have been disabled due to an error calculating surcharge fees. You may continue with any remaining invoices or refresh the page to try again.'
              : 'You have reached the maximum number of 10 transactions that can be processed simultaneously. Please come back later to make payments for any remaining invoices.  '
          }
          alertIcon='warning'
          onConfirm={
            showSurchargeModal
              ? () => {
                  setShowSurchargeModal(false);
                }
              : undefined
          }
          onClose={showSurchargeModal ? () => navigate(0) : undefined}
          confirmButtonText={showSurchargeModal ? 'Continue with Remaining Invoices' : undefined}
          cancelButtonText={showSurchargeModal ? 'Refresh Page' : 'Dismiss'}
        ></AlertModal>
        <InvoicesSubmitModal
          tabOpen={openSubmitModal}
          onConfirm={() => {
            setInvoiceSubmit(true);
          }}
          setModalAmounts={setModalAmounts}
          setModalSurcharges={setModalSurcharges}
          setModalRows={setModalRows}
        ></InvoicesSubmitModal>
        {tokenConfiguration !== null && displayedRows.length > 0 && invoice !== null && (
          // && recaptchaChecked
          <Grid container flexDirection={'row'} columnSpacing={2} className='customer-payable-container'>
            <Grid item xs={9}>
              <Grid container flexDirection={'column'}>
                <Grid item className='outstanding-amount' data-cy='top-outstanding-amount'>
                  Total Outstanding Amount: {formatTransactionCurrency(summary.totalRemainingBalance!)}
                </Grid>
                <Grid item xs={12} className='invoicelist-container'>
                  <Grid container flexDirection={'column'}>
                    <Grid container flexDirection={'column'} marginTop={'10px'} marginBottom={'10px'}>
                      <Grid item style={{ fontSize: '24px', fontWeight: '600' }}>
                        {summary.gatewayAddress?.addressModel.company}
                      </Grid>
                      <Grid item> {summary.gatewayAddress?.addressModel.addressLine1}</Grid>
                      {summary.gatewayAddress?.addressModel.addressLine2 && (
                        <Grid item>{summary.gatewayAddress?.addressModel.addressLine2}</Grid>
                      )}{' '}
                      <Grid
                        item
                      >{`${summary.gatewayAddress?.addressModel.city} ${summary.gatewayAddress?.addressModel.state} ${summary.gatewayAddress?.addressModel.postalCode}`}</Grid>
                      <Grid item></Grid>
                    </Grid>
                    <InvoiceStatusLegend />
                    {errorToken === null ? (
                      <TableContainer>
                        <Table size={'small'} aria-label='simple table'>
                          <TableHead>
                            <InvoiceHeaderRow
                              surcharge={surcharge}
                              binFilled={binFilled}
                              paymentTab={paymentTab}
                              selectAllInvoices={selectAllInvoices}
                              allChecked={allChecked}
                            ></InvoiceHeaderRow>
                          </TableHead>
                          <TableBody>
                            {displayedRows.map((row: CustomerPayableInterface) => {
                              return (
                                <>
                                  <InvoiceRow
                                    row={row}
                                    handleSelectInvoice={handleSelectInvoice}
                                    reachedMax={reachedMax}
                                    loadingTenSurcharges={loadingTenSurcharges}
                                    getSurchargeAmount={getSurchargeAmount}
                                    setAmountsToPay={setAmountsToPay}
                                  ></InvoiceRow>
                                </>
                              );
                            })}
                            <InvoiceListTotalsRow
                              displayedRows={displayedRows}
                              totalAmountToPay={totalAmountToPay}
                              totalSurcharge={totalSurcharge}
                            />
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <PaymentError errorCode={errorToken} isPayable={true} />
                    )}
                    {(selectedRows.length > 0 || surchargeError) && (
                      <Grid container flexDirection={'row'} className='max-invoice-div'>
                        <Grid item xs={0.5}>
                          <ErrorIcon className='warning' />
                        </Grid>
                        <Grid item xs={11.5}>
                          {!surchargeError &&
                            selectedRows.length > 0 &&
                            ` Each invoice selected will be processed as an individual transaction.
                        ${
                          selectedRows.length > 9
                            ? `You have reached the maximum number of transactions that can be
                        processed simultaneously. Please come back later to make payments for any remaining invoices.`
                            : `Total amount reflects the
                        total of all transactions.`
                        }`}
                          {surchargeError &&
                            `One or more invoices have been disabled due to an error calculating surcharge fees. You may continue with any remaining invoices or refresh the page to try again. `}
                        </Grid>
                      </Grid>
                    )}

                    <TablePagination
                      rowsPerPageOptions={[10]}
                      component='div'
                      count={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      nextIconButtonProps={{
                        ...((page > 8 || loadingSurcharge || loadingTenSurcharges) && { component: disabledTooltip }),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <TotalSelectedToPay
                    container={'invoice'}
                    amount={surcharge && paymentTab === 'card' ? totalAmountToPay + totalSurcharge : totalAmountToPay}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={3}>
              <Grid container direction={'column'}>
                <Grid item>
                  <TotalSelectedToPay
                    container={'payment'}
                    amount={surcharge && paymentTab === 'card' ? totalAmountToPay + totalSurcharge : totalAmountToPay}
                  />
                </Grid>
                <Grid item>
                  <CustomerPayablesPayment
                    payableToken={payableToken}
                    setInvoiceSubmit={setInvoiceSubmit}
                    customerPayable={{ invoiceObj: invoice }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {errorToken && displayedRows.length === 0 && <PaymentError errorCode={errorToken} isPayable={true} />}
      </CustomerPayablesContext.Provider>
    </>
  );
};

export default CustomerPayables;
