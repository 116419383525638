import React, { useEffect, useState, useImperativeHandle } from 'react';
import useScript from '../../../hooks/useScript.js';
import {
  AnyTokenizerProps,
  CARD_FORM_VALIDATION,
  correctInput,
  stringFormat,
  TokenExProps,
  TokenizerData,
} from './tokenizerHelpers';
import { ISaasConfig, ITokenExConfig } from '../../../core/interfaces/tokenizer.interface';
import axios from 'axios';
import { invoiceEndpoints } from '../../../core/constants/endpoints';
import { useTheme } from '@mui/material';

const TokenExComponent = ({
  tokenConfig,
  handleSubmit,
  handleError,
  tokenizerRef,
  validCard,
  invoiceToken,
}: TokenExProps) => {
  useImperativeHandle(tokenizerRef, () => ({
    validate() {
      triggerTokenizer();
    },
  }));
  const theme = useTheme();
  const config = tokenConfig;
  const styling = {
    base:
      theme.palette.mode === 'light'
        ? 'border: none; height: 48px; padding: 0px; vertical-align: -webkit-baseline-middle; color: #666; font-weight: 400; font-size: 16px;'
        : 'border: none; background: #212121; height: 48px; padding: 0px; vertical-align: -webkit-baseline-middle; color: white; font-weight: 400; font-size: 16px;',
    focus: 'outline: none',
    placeholder: 'color: rgb(186, 187, 189)',
    cvv: {
      base:
        theme.palette.mode === 'light'
          ? 'border: none; height: 48px; width: 100%; padding: 0px; vertical-align: -webkit-baseline-middle; color: #666; font-weight: 400; font-size: 16px'
          : 'border: none; background: #212121; height: 48px; width: 100%; padding: 0px; vertical-align: -webkit-baseline-middle; color: white; font-weight: 400; font-size: 16px',
      focus: 'outline: none',
      placeholder: 'color: rgb(186, 187, 189)',
    },
  };
  const [tokenizer, setTokenizer] = useState<AnyTokenizerProps | null>(null);
  const [loadedToken, setLoadedToken] = useState<boolean | null>(null);
  const [validForm, setValidForm] = useState(CARD_FORM_VALIDATION);
  const [exp, setExp] = useState<string | null>(null);
  const [expired, setExpired] = useState(false);

  const isLoading = useScript(process.env.REACT_APP_TOKENEX_URL, expired);

  // Load TokenEx Script and pass events to our configuration
  useEffect(() => {
    if (!isLoading[0]) {
      if (!expired) {
        /*global TokenEx*/
        const iframeConfig = setUpConfig(config);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const tokenExPCIIframe = new TokenEx.Iframe('iframe-container', iframeConfig);
        if (!loadedToken) {
          setTokenizer(tokenExPCIIframe);
          setLoadedToken(true);
        }
      } else if (expired && !loadedToken) {
        getGatewayTokenConfig();
      }
    }
  }, [isLoading]);

  // Upon loaded configuration call IFrame to load
  useEffect(() => {
    if (loadedToken) {
      tokenizer!.load();
      tokenizer!.on('blur', function () {
        tokenizer!.on('validate', function (data: TokenizerData) {
          if (data.isValid && validCard) {
            validCard(data.firstSix);
          } else if (validCard) {
            validCard(null);
          }
        });
        tokenizer!.validate();
      });

      tokenizer!.on('expired', function () {
        //call api for new config
        setLoadedToken(false);
      });
    } else if (loadedToken !== null) {
      setExpired(true);
    }
  }, [loadedToken]);

  const setUpConfig = (data: ISaasConfig): ITokenExConfig => {
    return {
      pci: true,
      inputType: 'text', //Options are number, tel or text. The default is text"
      enablePrettyFormat: true,
      debug: false,
      placeholder: '0000 0000 0000 0000',
      allowUnknownCardTypes: false,
      styles: styling,
      //required
      origin: data.origin,
      timestamp: data.timestamp,
      tokenExID: data.tokenizationId,
      tokenScheme: data.tokenScheme,
      authenticationKey: data.authenticationKey,
      enableValidateOnBlur: false,
      cvv: true,
      cvvContainerID: 'CvvTextbox',
      cvvPlaceholder: 'CVV',
      customDataTypes: [
        {
          type: 'txtCvvIframe',
          validRegex: '^[0-9]+$',
          possibleRegEx: '^[0-9]+$',
          maxLength: 16,
          cvvValidRegex: '^([0-9]{0,4})?$',
          cvvRequired: true,
          cvvMaxLength: 4,
          luhnCheck: true,
        },
      ],
    };
  };

  const triggerTokenizer = () => {
    tokenizer!.on('validate', function (data: TokenizerData) {
      setValidForm({
        ...validForm,
        exp: validateExp() || false,
        card: data.isValid,
        cvv: data.isCvvValid,
      });
      const validPayment = data.isValid && data.isCvvValid && validateExp();
      if (!validPayment) {
        handleError();
      }
      tokenizer!.on('tokenize', function (res: TokenizerData) {
        if (validPayment) {
          handleSubmit(res, exp);
        }
      });
    });
    tokenizer!.tokenize();
  };

  const validateExp = () => {
    const currentYear: number = new Date().getFullYear() % 100;
    const expYear: number = Number(exp?.split('/')[1]);
    return exp && exp?.length === 5 && expYear >= currentYear && expYear < currentYear + 10;
  };
  const changeExp = (data: React.ChangeEvent<HTMLInputElement>) => {
    setExp(data?.target.value);
  };

  const getGatewayTokenConfig = () => {
    if (tokenizer) {
      tokenizer!.remove();
      const url: string = stringFormat(invoiceEndpoints.tokenizationConfiguration, [invoiceToken!]);
      axios.get(url, { headers: { Authorization: invoiceToken } }).then((res) => {
        const iframeConfig = setUpConfig(res.data.data.iframeConfiguration.iqProV2);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const tokenExPCIIframe = new TokenEx.Iframe('iframe-container', iframeConfig);
        if (!loadedToken) {
          setTokenizer(tokenExPCIIframe);
        }
        setLoadedToken(true);
        setExpired(false);
        validCard(null);
      });
    }
  };

  return (
    <>
      <div className={'cvv tokenEx-card-wrapper'}>
        <div className={validForm.card ? 'card' : 'card invalid'}>
          <div className='card-row'>
            <div className='card-wrapper'>
              <div id='iframe-container'></div>
            </div>
          </div>
        </div>
        <div className={validForm.exp ? 'secondary' : 'secondary invalid'}>
          <div style={{ display: 'flex' }}>
            <input
              className='card-row exp'
              onKeyDown={correctInput}
              maxLength={5}
              onChange={changeExp}
              placeholder='MM/YY'
            />
          </div>
        </div>
        <div className={validForm.cvv ? 'secondary' : 'secondary invalid'}>
          <div className='card-row cvv' id='CvvTextbox'></div>
        </div>
      </div>
    </>
  );
};

export default TokenExComponent;
