import { TableCell, TableRow, Checkbox, Tooltip } from '@mui/material';
import { CustomerPayableInterface } from '../CustomerPayables/CustomerPayables';
import { Dispatch, SetStateAction, useContext } from 'react';
import '../../../assets/sass/pages/customerPayables.scss';
import InvoiceTableCells from './InvoiceTableCells';
import { CustomerPayablesContext, CustomerPayablesContextType } from '../../../contexts/CustomerPayablesContext';

type IInvoiceRowProps = {
  row: CustomerPayableInterface;
  handleSelectInvoice: (id: string) => void;
  setAmountsToPay: Dispatch<SetStateAction<{ [key: string]: string }>>;
  getSurchargeAmount: (idArray: string[], inputValue?: number | null, recalculating?: boolean) => void;
  loadingTenSurcharges: boolean;
  reachedMax: boolean;
};
const InvoiceRow = ({
  row,
  handleSelectInvoice,
  setAmountsToPay,
  getSurchargeAmount,
  loadingTenSurcharges,
  reachedMax,
}: IInvoiceRowProps) => {
  const {
    selectedRows,
    surcharge,
    paymentTab,
    savedSurcharges,
    amountToPay,
    binFilled,
    loadingSurcharge,
    surchargeState,
  } = useContext(CustomerPayablesContext) as CustomerPayablesContextType;
  const isItemSelected = selectedRows.map((r) => r.invoiceId![1]).includes(row.invoiceId![1]);
  const disableRowCondition =
    (surcharge && paymentTab === 'card' && (surchargeState === '' || !binFilled)) ||
    (paymentTab === 'ach' && row.invoicePaymentMethod['ach'] === false) ||
    (paymentTab === 'card' && row.invoicePaymentMethod['card'] === false) ||
    savedSurcharges[row.invoiceId![1]] === null ||
    (reachedMax && !selectedRows.map((r) => r.invoiceId![1]).includes(row.invoiceId![1]));
  return (
    <>
      <Tooltip
        title={
          surcharge && paymentTab === 'card' && (surchargeState === '' || !binFilled)
            ? 'Add payment details and billing state prior to selecton'
            : paymentTab === 'ach' && row.invoicePaymentMethod['ach'] === false
            ? 'This invoice must be paid via Card'
            : paymentTab === 'card' && row.invoicePaymentMethod['card'] === false
            ? 'This invoice must be paid via Bank Account'
            : ''
        }
      >
        <TableRow
          key={row.id}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          className={disableRowCondition ? 'disabled-row' : ''}
          role='checkbox'
          aria-checked={isItemSelected}
          tabIndex={-1}
          selected={isItemSelected}
        >
          <TableCell padding='checkbox'>
            <Tooltip title={loadingSurcharge ? 'Calculating surcharge' : ''}>
              <span>
                <Checkbox
                  data-cy={'invoice-row-checkbox'}
                  color='primary'
                  checked={isItemSelected}
                  disabled={loadingSurcharge || disableRowCondition}
                  onClick={() => handleSelectInvoice(row.invoiceId![1])}
                />
              </span>
            </Tooltip>
          </TableCell>
          <InvoiceTableCells
            type={'list'}
            row={row}
            surchargeList={savedSurcharges}
            amountList={amountToPay}
            setAmountList={setAmountsToPay}
            getSurchargeAmount={getSurchargeAmount}
            loadingTenSurcharges={loadingTenSurcharges}
          />
        </TableRow>
      </Tooltip>
    </>
  );
};

export default InvoiceRow;
