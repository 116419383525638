import { useLocation, useParams } from 'react-router';
import { Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import failImg from '../../../assets/images/payment/payment-cancelled.png';
import ErrorIcon from '@mui/icons-material/Error';
import successImg from '../../../assets/images/payment/invoice-paid.png';
import { formatTransactionCurrency } from '../../../core/helpers/stringHelpers';
import ReturnPaymentButton from './ReturnPaymentButton';

type TransactionResponse = {
  responseObj: {
    payableBatchId: string;
    processMultipleTransactionsResponse: Array<{
      invoiceId: string;
      status: {
        code: string;
        details: string[];
      };
      processTransactionResponse?: {
        transactionId?: string;
        processorResponseCode?: string;
        processorResponseText?: string;
        status?: string;
        response?: string;
        responseCode?: number;
        appliedToBalanceAmount?: number;
        remainingBalance?: number;
        transactionTotal?: number;
      };
    }>;
    multipleTransactionTotal: number;
  };
  amountToPay: { [key: string]: string };
  maskedPayment: string;
  savedSurcharges?: { [key: string]: number };
};

const InvoicesPaymentSummary = () => {
  const { payableToken } = useParams();
  const responseObject: TransactionResponse = useLocation().state;
  const transactionResponse = responseObject.responseObj.processMultipleTransactionsResponse;
  const acceptedInvoiceIds = transactionResponse.map((i) => {
    if (i?.processTransactionResponse?.response?.toLowerCase() === 'approved') {
      return i.invoiceId;
    }
  });

  const exitsRejectedTransactions =
    transactionResponse?.some((invoice) => invoice?.status.code !== 'Created') ||
    transactionResponse?.some((invoice) => invoice?.processTransactionResponse?.response?.toLowerCase() !== 'approved');

  const existsSuccessfulTransactions = transactionResponse?.some(
    (invoice) => invoice?.processTransactionResponse?.response?.toLowerCase() === 'approved'
  );

  return (
    <>
      <div style={{ fontWeight: '600', fontSize: '36px' }}>
        {exitsRejectedTransactions
          ? existsSuccessfulTransactions
            ? 'Payment Submitted with Errors'
            : 'Payment Submission Failed'
          : 'Payment Submitted Successfully'}
      </div>
      {exitsRejectedTransactions && (
        <Grid container flexDirection={'column'}>
          {' '}
          <Grid item>
            <div className='customer-payment-card'>
              <Grid container flexDirection={'row'} alignItems={'center'} spacing={1}>
                <Grid item xs={4}>
                  <img src={failImg} style={{ maxWidth: '200px' }}></img>
                </Grid>
                <Grid item xs={8}>
                  <Grid container flexDirection={'row'} className='payment-confirmation-warning'>
                    <Grid item xs={1}>
                      <ErrorIcon className='warning' />
                    </Grid>
                    <Grid item xs={11}>
                      An error has prevented some payment(s) from processing. Please check that your information is
                      correct and try again.
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <TableContainer>
                <Table size={'small'} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Invoice ID</TableCell>
                      <TableCell>Attempted Payment Amount</TableCell>
                      <TableCell>Error Reason</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactionResponse.map((invoice, i) => {
                      if (
                        invoice?.status.code !== 'Created' ||
                        invoice?.processTransactionResponse?.response?.toLowerCase() !== 'approved'
                      ) {
                        return (
                          <TableRow key={i}>
                            <TableCell>{invoice?.invoiceId || ''}</TableCell>
                            <TableCell>
                              {formatTransactionCurrency(Number(responseObject?.amountToPay[invoice?.invoiceId]))}
                            </TableCell>
                            <TableCell className='warning'>
                              {invoice.processTransactionResponse
                                ? invoice?.processTransactionResponse?.status
                                : invoice?.status?.code}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>{' '}
          <ReturnPaymentButton payableToken={payableToken} />
        </Grid>
      )}
      {existsSuccessfulTransactions && (
        <Grid container flexDirection={'column'}>
          {' '}
          <Grid item>
            <div className='customer-payment-card'>
              <Grid container flexDirection={'row'} alignItems={'center'}>
                <Grid item xs={5}>
                  <img src={successImg} style={{ maxWidth: '200px' }}></img>
                </Grid>
                <Grid item xs={7}>
                  <Grid container flexDirection='column'>
                    <Grid data-cy='amount-paid' item style={{ color: '#05BA69', fontSize: '24px', fontWeight: '600' }}>
                      {` Amount Paid: ${formatTransactionCurrency(
                        responseObject?.responseObj?.multipleTransactionTotal
                      )}`}
                    </Grid>
                    <Grid item>{`Payment Method: ${responseObject?.maskedPayment}`}</Grid>
                  </Grid>
                </Grid>
              </Grid>
              <TableContainer>
                <Table size={'small'} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Invoice ID</TableCell>
                      <TableCell>Transaction ID</TableCell>
                      <TableCell>Payment Amount</TableCell>{' '}
                      {responseObject?.savedSurcharges && <TableCell>Surcharge Amount</TableCell>}
                      <TableCell>Remaining Balance</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactionResponse.map((invoice, i) => {
                      if (invoice?.status?.code === 'Created' && invoice?.processTransactionResponse)
                        return (
                          <TableRow>
                            <TableCell>{invoice?.invoiceId}</TableCell>
                            <TableCell>{invoice?.processTransactionResponse?.transactionId}</TableCell>
                            <TableCell data-cy={'submission-table-paymentAmount' + i}>
                              {formatTransactionCurrency(invoice?.processTransactionResponse?.appliedToBalanceAmount)}
                            </TableCell>
                            {responseObject.savedSurcharges && (
                              <TableCell data-cy={'submission-table-surchargeAmount' + i}>
                                {formatTransactionCurrency(responseObject?.savedSurcharges[invoice?.invoiceId])}
                              </TableCell>
                            )}
                            <TableCell data-cy={'submission-table-remainingAmount' + i}>
                              {formatTransactionCurrency(invoice?.processTransactionResponse?.remainingBalance)}
                            </TableCell>
                          </TableRow>
                        );
                    })}
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell style={{ fontWeight: '600' }}>
                        <Grid container flexDirection={'column'}>
                          <Grid>Balance Payment: </Grid>
                          <Grid data-cy='balance-payment-amount'>
                            {formatTransactionCurrency(
                              transactionResponse.reduce((a, r) => {
                                if (r?.processTransactionResponse) {
                                  return a + r.processTransactionResponse.appliedToBalanceAmount!;
                                }
                                return a;
                              }, 0)
                            )}
                          </Grid>
                        </Grid>
                      </TableCell>
                      {responseObject.savedSurcharges && (
                        <TableCell style={{ fontWeight: '600' }}>
                          <Grid container flexDirection={'column'}>
                            <Grid>Surcharge: </Grid>
                            <Grid data-cy='surcharge-payment-amount'>
                              {formatTransactionCurrency(
                                Object.keys(responseObject.savedSurcharges).reduce((a, r) => {
                                  //filter our surcharge amount for failed invoice payments
                                  if (acceptedInvoiceIds?.includes(r)) {
                                    return Number((a + responseObject.savedSurcharges![r]).toFixed(2));
                                  }
                                  return a;
                                }, 0)
                              )}
                            </Grid>
                          </Grid>
                        </TableCell>
                      )}
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
          {exitsRejectedTransactions ? <></> : <ReturnPaymentButton payableToken={payableToken} />}
        </Grid>
      )}
    </>
  );
};

export default InvoicesPaymentSummary;
