import { TableCell, TableRow, Checkbox, Grid, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

type IInvoiceHeaderRowProps = {
  surcharge: boolean;
  binFilled?: string | boolean;
  paymentTab: string;
  selectAllInvoices?: () => void;
  allChecked?: boolean;
  isModal?: boolean;
};

const InvoiceHeaderRow = ({
  surcharge,
  binFilled,
  paymentTab,
  selectAllInvoices,
  allChecked,
  isModal = false,
}: IInvoiceHeaderRowProps) => {
  const columns = [
    { field: 'invoiceNum', headerName: 'Invoice #' },
    ...(!isModal ? [{ field: 'invoiceDate', headerName: 'Invoice Date' }] : []),
    { field: 'dueDate', headerName: 'Due Date' },
    { field: 'originalBalance', headerName: 'Original Balance' },
    { field: 'remainingBalance', headerName: 'Remaining Balance' },
    { field: 'amountPay', headerName: 'Amount To Pay Now' },
    ...(surcharge && paymentTab === 'card' ? [{ field: 'surcharge', headerName: 'Surcharge' }] : []),
    ...(surcharge && paymentTab === 'card' ? [{ field: 'total', headerName: 'Total' }] : []),
  ];
  return (
    <TableRow>
      {!selectAllInvoices && <TableCell></TableCell>}
      {selectAllInvoices && (
        <TableCell sx={{ textAlign: 'center' }}>
          <Checkbox
            sx={{ padding: '0px' }}
            color='primary'
            disabled={surcharge && !binFilled && paymentTab === 'card'}
            onClick={() => selectAllInvoices()}
            checked={allChecked}
            data-cy={'select-all-checkbox'}
          />
        </TableCell>
      )}
      {columns.map((c, i) =>
        c.field === 'amountPay' || c.field === 'surcharge' ? (
          <TableCell key={i}>
            <Grid container flexDirection={'row'} alignItems={'center'}>
              <Grid item xs={11} style={{ fontWeight: '600' }}>
                {c.headerName}
              </Grid>
              {selectAllInvoices && (
                <Grid item xs={1}>
                  <Tooltip
                    title={
                      c.field === 'surcharge'
                        ? 'A surcharge rate of up to 3.000% will be applied to credit card transactions, which does not exceed our cost of acceptance. This surcharge is not applied to debit card or ACH transactions.'
                        : 'Adjust the amount in the field to indicate a partial payment.'
                    }
                  >
                    <InfoIcon color='secondary'></InfoIcon>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </TableCell>
        ) : (
          <TableCell className={c.field === 'total' ? 'total-cell bold' : 'header-cells'} key={i}>
            {c.headerName}
          </TableCell>
        )
      )}
    </TableRow>
  );
};

export default InvoiceHeaderRow;
