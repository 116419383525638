import { Route, Routes, Navigate } from 'react-router';
import { invoiceRoutes } from '../../core/constants/routes';
import InvoiceLanding from '../Invoicing/Landing';
import InvoicePayment from '../Invoicing/Payment/Payment';
import CustomerPayables from '../Invoicing/CustomerPayables/CustomerPayables';
import Error from '../Error/Error';
import NotFound from './NotFound';
import InvoicesPaymentSummary from '../Invoicing/CustomerPayables/InvoicesPaymentSummary';

const InvoiceRoutes = () => {
  return (
    <Routes>
      {/* For now, redirect base path to invoice landing page 
          since we are only accepting payment for invoices.  */}
      <Route path='/' element={<Navigate to={invoiceRoutes.landing} />} />
      <Route path={invoiceRoutes.landing} element={<InvoiceLanding />} />
      <Route path={invoiceRoutes.payment} element={<InvoicePayment />} />
      <Route path={invoiceRoutes.customerPayable} element={<CustomerPayables />} />
      <Route path={invoiceRoutes.customerPaymentSummary} element={<InvoicesPaymentSummary />} />
      <Route path='/error' element={<Error />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

export default InvoiceRoutes;
